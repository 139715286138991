import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Chromelpez6 from "src/components/chromelpez6"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import Btf_cb_ez from "src/components/btf_cb_ez"
import CtaDisclosureNewtab from "@tightrope/ctadisclosurenewtab"


const chromelpezData = import("./data/data.json");
const btfData = import("./data/btfData.json");

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
       body {
         min-height:100vh;
         width: 100%;
         min-width: 728px;
         background: #fff;
         padding: 0;
         margin: 0;
         background-repeat: no-repeat;
         background-size: cover;
       }

       .footerlinks-module--br {
         display: none!important;
       }

       .Btf_cb_ez-module--btfCb button.Btf_cb_ez-module--install {
          border: none;
          background: #0078D4;
          color: #fff;
          font-weight: 500;
          font-size: 20pt;
          padding: 15px 50px;
          border-radius: 50px;
          cursor: pointer;
          letter-spacing: .5px;
          transition: .2s ease;
          margin: 25px auto;
          max-width: 300px;
       }
       #ctadisclosurenewtab-module--ctadisclaimer {
         max-width: 650px;
         margin: 0 auto;
       }

       #chromelpez-module--chromelpez .chromelpez-module--step-grid {
         margin: 25px auto;
       }
    `}
    </style>
    <style type="text/css">
         </style>
    <title>Print any web page easily - Easy Print</title></Helmet>
    <section>
      <Chromelpez6 data={chromelpezData}>
        <CtaDisclosureNewtab></CtaDisclosureNewtab>
      </Chromelpez6>
      <Btf_cb_ez data={btfData}>
        <CtaDisclosureNewtab></CtaDisclosureNewtab>
      </Btf_cb_ez>
      </section>
    </HomepageLayout>
  )
}
